<template>
  <div class="xl:w-[1200px] lg:w-[1020px]">
    <div class="flex items-center gap-4 md:gap-6 text-white px-4 pt-8 w-full">
      <router-link
        to="/"
        class="flex items-center justify-center gap-2 text-white"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
        <p class="uppercase text-lg max-lg:text-base max-sm:text-sm">Back</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuPage",
  setup() {
    return {};
  },
};
</script>
