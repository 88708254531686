import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import "./index.css";
import TheNav from "./components/ui/TheNav.vue";
import TheFooter from "./components/ui/TheFooter.vue";
import AdminContainer from "./pages/admin/AdminContainer.vue";
import "animate.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import "leaflet/dist/leaflet.css";

import CKEditor from "@ckeditor/ckeditor5-vue";

const app = createApp(App);

app.use(router);
app.use(CKEditor);

app.component("WebNav", TheNav);
app.component("WebFooter", TheFooter);
app.component("AdminContainer", AdminContainer);

library.add(faUserSecret, fas, fab, far);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
