<template>
  <div class="bg-[#010101] text-white">
    <!-- NAVBAR -->
    <HomeNavbar />

    <!-- ABOUT US -->
    <main class="flex items-center justify-center w-full pt-10 pb-20">
      <div class="max-w-[1200px] w-full max-[1210px]:px-5">
        <div class="flex justify-between items-center mb-10">
          <router-link
            to="/about"
            class="px-6 py-3 max-sm:px-3 max-sm:py-1 border border-white bg-[#353437] hover:border-[#f7c104] hover:bg-transparent"
          >
            Бидний тухай
          </router-link>
          <router-link
            to="/jobs"
            class="px-6 py-3 max-sm:px-3 max-sm:py-1 border border-white hover:border-[#f7c104] hover:bg-transparent"
          >
            Ажлын байр
          </router-link>
        </div>
        <div class="flex gap-10 max-lg:flex-col">
          <swiper
            :effect="'cube'"
            :grabCursor="true"
            :centeredSlides="true"
            :slidesPerView="1"
            :cubeEffect="{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.65,
            }"
            :pagination="{ clickable: true }"
            :loop="true"
            :autoplay="{ delay: 5000, pauseOnMouseEnter: true }"
            :modules="modules"
            class="slide-in-left w-1/2 h-[400px] max-lg:w-full max-sm:h-[200px] mySwiper"
          >
            <swiper-slide v-for="(image, index) in images" :key="index">
              <img
                :src="image.content"
                :alt="image.content"
                class="w-full h-full object-cover object-center"
              />
            </swiper-slide>
          </swiper>
          <div
            class="fade-in flex flex-col justify-center gap-y-5 text-white tracking-wider"
          >
            <p
              v-for="(editorData, index) in text"
              :key="index"
              v-html="editorData.content"
            ></p>
          </div>
        </div>
      </div>
    </main>
  </div>

  <!-- Footer -->
  <TheFooter />
</template>

<script>
import { ref, onMounted } from "vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import HomeNavbar from "@/components/ui/HomeNavbar.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { EffectCube, Pagination, Autoplay } from "swiper/modules";
import instance from "@/lib/api/clientInstance";

export default {
  name: "AboutUs",
  components: {
    HomeNavbar,
    TheFooter,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const isLoading = ref(false);
    const images = ref([]);
    const text = ref("");

    onMounted(async () => {
      try {
        isLoading.value = true;
        const imageResponse = await instance.get("/about-us/image");
        images.value = imageResponse.data.image;
        const textResponse = await instance.get("/about-us/text");
        text.value = textResponse.data.text;
      } catch (error) {
        return error;
      } finally {
        isLoading.value = false;
      }
    });

    return {
      images,
      text,
      isLoading,
      modules: [EffectCube, Pagination, Autoplay],
    };
  },
};
</script>

<style scoped>
.mySwiper {
  --swiper-pagination-color: #f7c104;
  --swiper-pagination-bullet-inactive-color: #fff;
}
.slide-in-left {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
