<template>
  <admin-container>
    <h1 class="text-3xl font-bold mb-8">Ширээ захиалга</h1>
    <div class="grid grid-cols-1 md:grid-cols-9 gap-4 mb-8">
      <!-- reset filter button -->

      <div class="col-span-1 md:col-span-2">
        <label for="branch" class="block mb-2 text-sm font-medium text-gray-900"
          >Салбар</label
        >
        <select
          id="branch"
          name="branch"
          v-model="filterValue.branch"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option selected disabled>Салбар сонгох</option>
          <option value="4">Gate 4</option>
          <option value="5">Gate 5</option>
          <option value="6">Gate 6</option>
        </select>
      </div>
      <div class="col-span-1 md:col-span-2">
        <label for="status" class="block mb-2 text-sm font-medium text-gray-900"
          >Статус</label
        >
        <select
          id="status"
          name="status"
          v-model="filterValue.status"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option selected disabled>Статус сонгох</option>
          <option value="confirmed">Confirmed</option>
          <option value="pending">Pending</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>
      <div class="col-span-1 md:col-span-2">
        <label for="date" class="block mb-2 text-sm font-medium text-gray-900"
          >Огноо</label
        >
        <input
          type="date"
          id="date"
          name="date"
          v-model="filterValue.date"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>

      <div
        class="col-span-1 md:col-span-3 relative flex flex-col justify-center focus-within:text-blue-500"
      >
        <label for="search" class="block mb-2 text-sm font-medium text-gray-900"
          >Хайх</label
        >
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Нэр, утас, имэйл, захиалгийн ID"
          v-model="searchValue"
          @keyup.enter="handleSearch"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
        <button
          type="button"
          class="absolute right-0 top-[40px] mr-2.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          @click="handleSearch"
        >
          <svg
            class="w-5 h-5 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="10" cy="10" r="7"></circle>
            <line x1="21" y1="21" x2="15" y2="15"></line>
          </svg>
        </button>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center"
      v-if="reservationList && reservationList.length > 0 && !isLoading"
    >
      <hot-table
        :settings="hotSettings"
        :data="reservationList"
        ref="hotTableRef"
      ></hot-table>
    </div>
    <!-- <div v-else-if="isLoading" class="flex justify-center items-center h-96">
      <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div> -->
    <div v-if="isLoading" class="h-[400px]"></div>
    <div
      class="flex items-center justify-center h-[400px]"
      v-else-if="reservationList && reservationList.length === 0 && !isLoading"
    >
      <h1 class="text-2xl font-bold mb-4">Ширээ захиалга байхгүй байна.</h1>
    </div>
    <!-- render viprooms image -->
    <div class="grid grid-cols-3 md:flex gap-4 mt-4">
      <div>
        <p class="mb-1">Gate 4 Өрөө 1</p>
        <img
          src="../../assets/images/vip-room/gate4_1.jpg"
          alt="vip room 1"
          class="h-36 object-fit rounded-md"
        />
      </div>
      <div>
        <p class="mb-1">Gate 5 Өрөө 1</p>
        <img
          src="../../assets/images/vip-room/gate5_1.jpg"
          alt="vip room 1"
          class="h-36 object-fit rounded-md"
        />
      </div>
      <!-- <div>
        <p class="mb-1">Gate 5 Өрөө 2</p>
        <img src="../../assets/images/vip-room/gate5_2.jpg" alt="vip room 1" class="h-36 object-fit rounded-md" />
      </div> -->
      <div>
        <p class="mb-1">Gate 6 Өрөө 1</p>
        <img
          src="../../assets/images/vip-room/gate6_1.jpeg"
          alt="vip room 1"
          class="h-36 object-fit rounded-md"
        />
      </div>
      <div>
        <p class="mb-1">Gate 6 Өрөө 2</p>
        <img
          src="../../assets/images/vip-room/gate6_2.jpeg"
          alt="vip room 1"
          class="h-36 object-fit rounded-md"
        />
      </div>
      <div>
        <p class="mb-1">Gate 6 Өрөө 3</p>
        <img
          src="../../assets/images/vip-room/gate6_3.jpg"
          alt="vip room 1"
          class="h-36 object-fit rounded-md"
        />
      </div>
    </div>
    <error-modal :error="error" />
    <success-modal :success="success" />
  </admin-container>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import adminInstance from "@/lib/api/adminInstance";
import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";
import { vipRooms } from "@/lib/constants";
registerAllModules();
export default {
  name: "ReservationList",
  components: {
    HotTable,
    ErrorModal,
    SuccessModal,
  },
  setup() {
    const hotTableRef = ref(null);
    const reservationList = ref([]);

    const filterValue = reactive({
      branch: "",
      status: "",
      date: "",
    });

    const searchValue = ref("");

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);
    /*
      fetch reservation list
    */
    const fetchReservationList = async () => {
      try {
        isLoading.value = true;
        const params = new URLSearchParams(filterValue).toString();
        const response = await adminInstance.get(`/reservation?${params}`);
        reservationList.value = response.data.reservation;
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchReservationList();
    });

    /*
      filter function
    */
    watch(
      () => [filterValue.branch, filterValue.status, filterValue.date],
      async ([newBranch], [oldBranch]) => {
        if (newBranch !== oldBranch) {
          filterValue.status = "";
          filterValue.date = "";
        }
        await fetchReservationList();
      }
    );

    const handleSearch = async () => {
      try {
        if (!searchValue.value) return;
        isLoading.value = true;
        filterValue.branch = "";
        filterValue.status = "";
        filterValue.date = "";
        const response = await adminInstance.get(
          `/reservation?search=${searchValue.value}`
        );
        reservationList.value = response.data.reservation;
      } catch (err) {
        error.value = err.response.data.message;
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    /*
      handle confirm БАТЛАХ button
    */
    let isConfirming = false;
    const handleConfirm = async (row) => {
      if (isConfirming) return;
      isConfirming = true;
      if (reservationList.value[row].status === "confirmed") {
        error.value = "Захиалга баталгаажсан байна.";
        resetMessage();
        return;
      }
      const reservationId = reservationList.value[row].id;

      try {
        const response = await adminInstance.post(
          `/reservation/confirm/${reservationId}`
        );
        if (response.status === 200) {
          reservationList.value[row].is_confirmed = response.data.isConfirmed;
          reservationList.value[row].status = response.data.status;
          success.value = response.data.message;
          hotTableRef.value.hotInstance.render();
        }
      } catch (err) {
        error.value = err.response.data.message;
        console.error(err);
      } finally {
        isConfirming = false;
        resetMessage();
      }
    };

    /*
      handle edit ӨӨРЧЛӨХ button
    */
    const handleEdit = async (row) => {
      const reservationId = reservationList.value[row].id;
      const branch = reservationList.value[row].branch;
      const reservationType = reservationList.value[row].reservation_type;
      const name = reservationList.value[row].name;
      const phone = reservationList.value[row].phone;
      const email = reservationList.value[row].email;
      const date = reservationList.value[row].date;
      const time = reservationList.value[row].time;
      const numberOfPeople = reservationList.value[row].number_of_people;
      const vipRoom = reservationList.value[row].vip_room;
      const description = reservationList.value[row].description;
      const status = reservationList.value[row].status;

      try {
        const response = await adminInstance.put(
          `/reservation/${reservationId}`,
          {
            branch,
            reservationType,
            name,
            phone,
            email,
            date,
            time,
            numberOfPeople,
            vipRoom,
            description,
            status,
          }
        );

        if (response.status === 200) {
          reservationList.value[row].is_confirmed = response.data.isConfirmed;
          reservationList.value[row].status = response.data.status;
          success.value = response.data.message;
          hotTableRef.value.hotInstance.render();
        }
      } catch (err) {
        error.value = err.response.data.message;
        console.error(err);
      } finally {
        resetMessage();
      }
    };

    /*
      renderers
    */
    const dateInputRenderer = (instance, td, row, col, prop, value) => {
      const now = new Date();
      const currentDate = now.toISOString().split("T")[0];
      const cellValue = value || "";
      const dateId = `date-input-${row}`;
      td.innerHTML = `<input id="${dateId}" type="date" value="${cellValue}" min="${currentDate}" class="w-full h-full text-center text-[#222222] md:font-semibold" />`;
      //  update date value
      const dateInput = document.getElementById(dateId);
      if (dateInput) {
        dateInput.addEventListener("change", () => {
          reservationList.value[row].date = dateInput.value;
        });
      }
      return td;
    };

    const timeInputRenderer = (instance, td, row, col, prop, value) => {
      const cellValue = value || "";
      const timeId = `time-input-${row}`;
      td.innerHTML = `<input id="${timeId}" type="time" value="${cellValue}" class="w-full h-full text-center text-[#222222] md:font-semibold" />`;
      //  update time value
      const timeInput = document.getElementById(timeId);
      if (timeInput) {
        timeInput.addEventListener("change", () => {
          reservationList.value[row].time = timeInput.value;
        });
      }

      return td;
    };

    const actionRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      const editButtonId = `edit-button-${row}`;
      const confirmButton = `confirm-button-${row}`;
      const buttonText = value ? "Баталгаажсан" : "Батлах";

      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
        <button id="${editButtonId}" class="w-28 bg-blue-500 text-white rounded-md text-center p-1 hover:bg-blue-600 transition ease-in-out duration-150">Өөрчлөх</button>
        <button id="${confirmButton}" class="w-28 bg-green-500 text-white rounded-md text-center p-1 hover:bg-green-600 transition ease-in-out duration-150">${buttonText}</button>
      </div>`;

      const editButton = document.getElementById(editButtonId);
      const confirmButtonElement = document.getElementById(confirmButton);

      if (editButton) {
        editButton.addEventListener("click", () => {
          handleEdit(row);
        });
      }
      if (confirmButtonElement) {
        confirmButtonElement.addEventListener("click", () => {
          handleConfirm(row);
        });
      }
    };

    const dropDownRenderer = (instance, td, row, col, prop, value) => {
      const status = instance.getDataAtRowProp(row, "status");
      if (status === "pending") {
        td.style.backgroundColor = "#FDE68A";
      } else if (status === "confirmed") {
        td.style.backgroundColor = "#A7F3D0";
      } else if (status === "cancelled") {
        td.style.backgroundColor = "#FECACA";
      }
      td.classList.add("htMiddle");
      td.innerHTML = `<div class="flex justify-center items-center gap-2">
              <span class="text-[#222222] md:font-semibold text-center">${value}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414L10 9.586l2.293-2.293a1 1 0 011.414 1.414l-3 3A1 1 0 0110 12z" clip-rule="evenodd" />
              </svg>
              </div>`;
      return td;
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      // Get the status from the data source
      const status = instance.getDataAtRowProp(row, "status");

      // Set background color based on status
      if (status === "pending") {
        td.style.backgroundColor = "#FDE68A";
      } else if (status === "confirmed") {
        td.style.backgroundColor = "#A7F3D0";
      } else if (status === "cancelled") {
        td.style.backgroundColor = "#FECACA";
      }

      td.classList.add("htMiddle");
      td.innerHTML = `<span class="text-[#222222] md:font-semibold text-center">${value}</span>`;
      return td;
    };
    /*
      end of renderers
    */

    /*
      settings
    */
    const hotSettings = {
      data: reservationList.value,
      colHeaders: [
        "Захиалгийн ID",
        "Нэр",
        "Дугаар",
        "Салбар (Gate-)",
        "Төрөл",
        "VIP өрөө",
        "Имайл",
        "Огноо",
        "Цаг",
        "Хүний тоо",
        "Тайлбар",
        "Үүсгэсэн огноо",
        "Статус",
        "Үйлдэл",
      ],
      columns: [
        { data: "reservation_id", readOnly: true, renderer: commonRenderer },
        { data: "name", renderer: commonRenderer },
        { data: "phone", renderer: commonRenderer },
        {
          data: "branch",
          allowInvalid: false,
          type: "dropdown",
          source: ["4", "5", "6"],
          renderer: dropDownRenderer,
        },
        {
          data: "reservation_type",
          allowInvalid: false,
          type: "dropdown",
          source: ["regular", "vip"],
          renderer: dropDownRenderer,
        },
        {
          data: "vip_room",
          allowInvalid: false,
          type: "dropdown",
          source: ["1", "2", "3"],

          renderer: (instance, td, row, col, prop, value) => {
            const status = instance.getDataAtRowProp(row, "status");
            if (status === "pending") {
              td.style.backgroundColor = "#FDE68A";
            } else if (status === "confirmed") {
              td.style.backgroundColor = "#A7F3D0";
            } else if (status === "cancelled") {
              td.style.backgroundColor = "#FECACA";
            }
            td.classList.add("htMiddle");
            const reservationType = instance.getDataAtRowProp(
              row,
              "reservation_type"
            );
            if (reservationType === "vip") {
              td.innerHTML = `<div class="flex justify-center items-center gap-2">
              <span class="text-[#222222] md:font-semibold text-center">${value}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414L10 9.586l2.293-2.293a1 1 0 011.414 1.414l-3 3A1 1 0 0110 12z" clip-rule="evenodd" />
              </svg>
              </div>`;
            } else {
              value = "";
              td.innerHTML = `<div class="flex justify-center items-center gap-2">
              <span class="text-[#222222] md:font-semibold text-center">${value}</span>
              </div>`;
            }
            return td;
          },
        },
        { data: "email", renderer: commonRenderer },
        { data: "date", readOnly: true, renderer: dateInputRenderer },
        { data: "time", readOnly: true, renderer: timeInputRenderer },
        { data: "number_of_people", type: "numeric", renderer: commonRenderer },
        { data: "description", width: "250px", renderer: commonRenderer },
        { data: "created_at", readOnly: true, renderer: commonRenderer },
        {
          data: "status",
          allowInvalid: false,
          type: "dropdown",
          source: ["pending", "confirmed", "cancelled"],
          renderer: dropDownRenderer,
        },
        { data: "is_confirmed", readOnly: true, renderer: actionRenderer },
      ],

      stretchH: "all",
      fixedColumnsLeft: window.innerWidth < 768 ? 0 : 3,
      width: "100%",
      height: 400,
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    return {
      hotTableRef,
      error,
      success,
      isLoading,
      reservationList,
      vipRooms,
      hotSettings,
      filterValue,
      searchValue,
      fetchReservationList,
      handleSearch,
    };
  },
};
</script>
