<template>
  <admin-container>
    <div class="mb-4">
      <h1 class="text-2xl font-bold mb-4">Ажил хүсэлт</h1>
      <hot-table :settings="hotSettings" v-if="!isLoading && jobRequestList.length > 0" :data="jobRequestList"></hot-table>
      <div v-if="isLoading" class="h-[400px]"></div>
      <div class="flex items-center justify-center h-[400px]" v-else-if="jobRequestList && jobRequestList.length === 0 && !isLoading">
        <h1 class="text-2xl font-bold mb-4">Хүсэлт байхгүй байна</h1>
      </div>
    </div>
    <div>
      <h1 class="text-2xl font-bold mb-4">Ажил зар</h1>
      <hot-table :settings="hotSettingsJob" v-if="!isLoading && jobList.length > 0" :data="jobList"></hot-table>
      <div v-if="isLoading" class="h-[400px]"></div>
      <div class="flex items-center justify-center h-[400px]" v-else-if="jobList && jobList.length === 0 && !isLoading">
        <h1 class="text-2xl font-bold mb-4">Ажил байхгүй байна</h1>
      </div>
    </div>
  </admin-container>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import adminInstance from "@/lib/api/adminInstance";
registerAllModules();
export default {
  name: "JobRequestList",
  components: {
    HotTable,
  },
  setup() {
    const router = useRouter();
    const jobRequestList = ref([]);
    const jobList = ref([]);

    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      try {
        const response = await adminInstance.get("/job-request-list");
        const jobResponse = await adminInstance.get("/jobs");
        if (response.status === 200) {
          jobRequestList.value = response.data.jobRequestList;
        }
        if (jobResponse.status === 200) {
          jobList.value = jobResponse.data.jobs;
        }
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    });

    const handleDeleteRow = async (row) => {
      try {
        const response = await adminInstance.delete(`/job-request-list/${jobRequestList.value[row].id}`);
        if (response.status === 200) {
          jobRequestList.value.splice(row, 1);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = `<span class="text-[#222222] font-semibold text-center">${value}</span>`;
    };

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");

      const deleteButtonId = `delete-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2">
        <button id="${deleteButtonId}" class="bg-red-500 text-white rounded-md text-center p-2">Устгах</button>
      </div>`;

      const deleteButton = document.getElementById(deleteButtonId);

      if (deleteButton) {
        deleteButton.addEventListener("click", () => handleDeleteRow(row));
      }
    };

    const hotSettings = {
      data: jobRequestList.value,
      colHeaders: ["Овог", "Нэр", "Имэйл", "Утас", "Ажил", "Үндсэн мэргэжил", "Туршлага", "Хүсэлт илгээсэн огноо"],
      columns: [
        {
          data: "last_name",
          type: "text",
          renderer: commonRenderer,
        },
        {
          data: "first_name",
          type: "text",
          renderer: commonRenderer,
        },
        {
          data: "email",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "phone",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "requested_job",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "primary_occuptation",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "experience",
          readOnly: true,
          renderer: commonRenderer,
        },
        // {
        //   data: "cv_file",
        //   readOnly: true,
        //   renderer: cvRenderer,

        // },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "action",
          readOnly: true,
          renderer: actionRenderer,
        },
      ],
      stretchH: "all",
      fixedColumnsLeft: window.innerWidth > 768 ? 3 : 0,
      width: "100%",
      height: 400,
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const handleJobActive = async (row) => {
      try {
        const response = await adminInstance.put(`/jobs/${jobList.value[row].id}`, {
          is_active: !jobList.value[row].is_active,
        });
        if (response.status === 200) {
          jobList.value[row].is_active = !jobList.value[row].is_active;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleJobDelete = async (row) => {
      try {
        const response = await adminInstance.delete(`/jobs/${jobList.value[row].id}`);
        if (response.status === 200) {
          jobList.value.splice(row, 1);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const checkboxRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.classList.add("htCenter");
      td.innerHTML = `<input type="checkbox" ${value ? "checked" : ""} class="form-checkbox h-5 w-5 text-blue-600"  />`;
      const checkbox = td.querySelector("input");
      checkbox.addEventListener("click", () => handleJobActive(row));
    };

    const actionRendererJob = (instance, td, row) => {
      td.classList.add("htMiddle");

      td.innerHTML = `<div class="flex justify-center items-center gap-2">
        <button class="edit-button bg-blue-500 text-white rounded-md text-center p-2">Засах</button>
    <button class="delete-button bg-red-500 text-white rounded-md text-center p-2">Устгах</button>
  </div>`;

      td.querySelector(".edit-button").addEventListener("click", () => router.push(`/admin/add-job/${jobList.value[row].id}`));
      td.querySelector(".delete-button").addEventListener("click", () => handleJobDelete(row));
    };

    const hotSettingsJob = {
      data: jobList.value,
      colHeaders: ["Ажил", "Идэвхтэй", "Үйлдэл"],
      columns: [
        {
          data: "job_name",
          type: "text",
          renderer: commonRenderer,
        },
        {
          data: "is_active",
          type: "text",
          readOnly: true,
          renderer: checkboxRenderer,
        },
        {
          data: "action",
          type: "text",
          readOnly: true,
          renderer: actionRendererJob,
        },
      ],
      stretchH: "all",
      fixedColumnsLeft: window.innerWidth < 768 ? 3 : 0,
      width: "100%",
      height: 400,
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    return {
      isLoading,
      jobRequestList,
      jobList,
      hotSettings,
      hotSettingsJob,
    };
  },
};
</script>
