<template>
  <div class="w-full h-full bg-[#010101]">
    <HomeNavbar />
    <!-- Header зураг -->
    <header class="w-full h-[20rem] max-sm:h-[10rem]">
      <img
        src="../assets/images/gate-5/main-hall.jpg"
        alt="main hall"
        class="h-full w-full object-fit object-cover"
      />
    </header>

    <main class="flex justify-center items-center text-white">
      <div
        class="flex gap-x-10 max-w-[1200px] w-full my-20 max-sm:my-10 max-md:flex-col max-[1200px]:p-5 max-md:gap-y-10"
      >
        <!-- Санал хүсэлтийн хэсэг -->
        <div v-if="isLoading" class="flex justify-center items-center">
          <svg class="animate-spin h-5 w-5" viewBox="0 0 24 24"></svg>
        </div>
        <div v-else class="w-full">
          <h1 class="text-4xl text-[#f7c104] font-bold">Санал хүсэлт</h1>
          <p class="text-lg tracking-wide">
            Танд бидэнд илгээх санал хүсэлт байвал доор үлдээгээрэй
          </p>
          <div class="flex gap-x-10">
            <form
              action=""
              @submit.prevent="handleSubmit"
              class="w-full space-y-5 mt-10"
            >
              <div class="flex flex-col gap-y-2">
                <label for="name"
                  >Нэр<span class="text-xl text-red-500">*</span></label
                >
                <input
                  type="text"
                  id="name"
                  name="name"
                  autocomplete="off"
                  class="bg-[#353437] p-2"
                  v-model="name"
                  required
                />
              </div>
              <div class="flex flex-col gap-y-2">
                <label for="email"
                  >И-мэйл<span class="text-xl text-red-500">*</span></label
                >
                <input
                  type="text"
                  id="email"
                  name="email"
                  autocomplete="off"
                  class="bg-[#353437] p-2"
                  v-model="email"
                  required
                />
              </div>
              <div class="flex flex-col gap-y-2">
                <label for="feedback"
                  >Санал хүсэлт<span class="text-xl text-red-500"
                    >*</span
                  ></label
                >
                <textarea
                  type="text"
                  id="feedback"
                  name="feedback"
                  autocomplete="off"
                  class="bg-[#353437] p-2 h-[10rem]"
                  v-model="feedback"
                  required
                >
                </textarea>
              </div>
              <button class="px-4 py-2 bg-[#f7c104] text-black hover:scale-105">
                Илгээх
              </button>
            </form>
          </div>
        </div>

        <!-- MAP -->
        <div class="w-full">
          <div class="flex w-full">
            <button
              @click="toggleLocations('gate-4')"
              class="w-1/3 text-center p-2 hover:text-[#f7c104] focus:text-black"
              :class="
                selectedLocation === 'gate-4' ? 'bg-[#f7c104] text-black' : ''
              "
            >
              Gate 4
            </button>
            <button
              @click="toggleLocations('gate-5')"
              class="w-1/3 text-center p-2 hover:text-[#f7c104] focus:text-black"
              :class="
                selectedLocation === 'gate-5' ? 'bg-[#f7c104] text-black' : ''
              "
            >
              Gate 5
            </button>
            <button
              @click="toggleLocations('gate-6')"
              class="w-1/3 text-center p-2 hover:text-[#f7c104] focus:text-black"
              :class="
                selectedLocation === 'gate-6' ? 'bg-[#f7c104] text-black' : ''
              "
            >
              Gate 6
            </button>
          </div>
          <div
            class="flex justify-center items-center h-full pb-10 max-sm:h-[20rem]"
          >
            <l-map :zoom="zoom" :center="location" :min-zoom="13">
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              ></l-tile-layer>
              <l-marker :lat-lng="location" :icon="customIcon"></l-marker>
            </l-map>
          </div>
        </div>
      </div>
    </main>

    <TheFooter />

    <transition name="fade">
      <div
        v-if="error"
        class="fixed top-0 right-2 r flex items-center justify-center px-4 py-6 pointer-events-none"
      >
        <div
          class="max-w-sm w-full bg-red-500 text-white p-4 rounded-lg shadow-lg text-md flex items-center space-x-2"
        >
          <span>{{ error }}</span>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="success"
        class="fixed top-0 right-2 r flex items-center justify-center px-4 py-6 pointer-events-none"
      >
        <div
          class="max-w-sm w-full bg-green-500 text-white p-4 rounded-lg shadow-lg text-md flex items-center space-x-2"
        >
          <span>{{ success }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import HomeNavbar from "../components/ui/HomeNavbar.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import L from "leaflet";
import instance from "@/lib/api/clientInstance";

export default {
  name: "ContactPage",
  components: {
    HomeNavbar,
    LMap,
    LTileLayer,
    LMarker,
    TheFooter,
  },
  setup() {
    //Санал хүсэлт
    const name = ref("");
    const email = ref("");
    const feedback = ref("");
    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    const resetForm = () => {
      name.value = "";
      email.value = "";
      feedback.value = "";
    };

    const resetMessage = () => {
      setTimeout(() => {
        success.value = "";
        error.value = "";
      }, 3000);
    };

    const handleSubmit = async () => {
      try {
        const data = {
          name: name.value,
          email: email.value,
          feedback: feedback.value,
        };
        isLoading.value = true;
        const response = await instance.post("/customer-feedback", data);
        if (response.status === 200) {
          success.value = response.data.message;
          resetMessage();
          resetForm();
        }
      } catch (error) {
        error.value = error.response.data.message;
        resetMessage();
      } finally {
        isLoading.value = false;
      }
    };

    // Map
    const url = ref("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    const attribution = ref(
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    );
    const zoom = computed(() => {
      if (window.innerWidth < 600) {
        return 16;
      } else {
        return 17;
      }
    });
    const customIcon = L.icon({
      iconUrl: require("../assets/logo.jpg"),
      iconSize: [28, 28],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    const selectedLocation = ref("");
    const location = ref([47.9168, 106.90224]);
    const toggleLocations = (value) => {
      selectedLocation.value = value;
      if (value === "gate-4") {
        location.value = [47.9168, 106.90224];
      } else if (value === "gate-5") {
        location.value = [47.92297, 106.91308];
      } else if (value === "gate-6") {
        location.value = [47.92102, 106.92281];
      }
    };

    onMounted(() => {
      toggleLocations("gate-4");
    });

    return {
      name,
      email,
      feedback,
      handleSubmit,
      url,
      attribution,
      zoom,
      customIcon,
      toggleLocations,
      selectedLocation,
      location,
      error,
      success,
      isLoading,
    };
  },
};
</script>
