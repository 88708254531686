<template>
  <header
    class="flex justify-center items-center w-full"
    :class="route.path === '/' ? 'backdrop-blur' : ''"
  >
    <!-- For Small Screen -->
    <div
      ref="navbarContainer"
      class="flex justify-between items-center w-full text-white py-5 px-5 sm:hidden"
    >
      <router-link to="/" class="flex items-center gap-x-3 h-10">
        <img src="../../assets/logo.jpg" alt="Gate" class="h-10 w-10" />
        <p
          class="flex flex-col justify-center font-light text-sm leading-[0.5rem] tracking-widest"
        >
          <span class="font-bold text-2xl tracking-wider">GATE</span>Concept Bar
        </p>
      </router-link>
      <button @click="toggleNavbar" class="hover:text-[#f7c104]">
        <font-awesome-icon icon="fa-solid fa-bars" class="w-5 h-5" />
      </button>
    </div>

    <!-- Side navbar -->
    <Transition name="slide-fade" class="bg-black bg-opacity">
      <div
        v-if="isNavbarOpen"
        class="absolute top-0 right-0 z-[999] bg-white p-5 text-black w-2/3 sm:w-1/2 h-screen"
        id="navbar"
      >
        <div class="flex justify-end">
          <button @click="toggleNavbar" class="hover:text-[#f7c104]">
            <font-awesome-icon
              icon="fa-solid fa-x"
              class="text-black h-3 w-3"
            />
          </button>
        </div>
        <div class="flex flex-col h-full justify-between py-5">
          <nav class="space-y-6 tracking-wide">
            <router-link
              to="/about"
              @click="toggleNavbar"
              class="flex gap-2 items-center hover:text-[#f7c104]"
            >
              <img
                src="../../assets/svg/shop-icon.svg"
                alt="branch"
                class="h-4 w-5"
              />
              About Us</router-link
            >
            <router-link
              :to="{ path: '/', hash: '#menu' }"
              @click="toggleNavbar"
              class="flex gap-2 items-center hover:text-[#f7c104]"
            >
              <img
                src="../../assets/svg/dish-spoon-knife-icon.svg"
                alt="dish"
                class="h-5 w-5"
              />Menu
            </router-link>
            <router-link
              :to="{ path: '/', hash: '#billiard' }"
              @click="toggleNavbar"
              class="flex gap-2 items-center hover:text-[#f7c104]"
            >
              <img
                src="../../assets/svg/sing-icon.svg"
                alt="mic"
                class="h-5 w-5"
              />
              Billiard Club
            </router-link>
            <router-link
              to="/book-table"
              @click="toggleNavbar"
              class="flex gap-2 items-center hover:text-[#f7c104]"
            >
              <img
                src="../../assets/svg/calendar-checkmark-icon.svg"
                alt="calendar"
                class="h-4 w-5"
              />
              Reservations</router-link
            >
            <router-link
              to="/jobs"
              @click="toggleNavbar"
              class="flex gap-2 items-center hover:text-[#f7c104]"
            >
              <font-awesome-icon icon="fa-solid fa-user-tie" class="h-5 w-5" />
              Career</router-link
            >
            <router-link
              to="/contact"
              @click="toggleNavbar"
              class="flex gap-2 items-center hover:text-[#f7c104]"
            >
              <font-awesome-icon icon="fa-solid fa-phone" class="h-4 w-5" />
              Contact
            </router-link>
          </nav>
          <p class="text-black text-center text-gray-500 text-sm">
            Copyright &copy; 2024
          </p>
        </div>
      </div>
    </Transition>

    <!-- backdrop -->
    <transition name="fade">
      <div
        v-if="isNavbarOpen"
        class="fixed top-0 left-0 z-[998] w-full h-screen bg-black bg-opacity-50"
        @click="toggleNavbar"
      ></div>
    </transition>

    <!-- For Larger Screen -->
    <nav
      class="hidden sm:flex justify-between items-center max-w-[1200px] w-full text-white my-5 max-sm:flex-col max-[1210px]:px-5"
    >
      <router-link to="/" class="flex items-center gap-x-3 h-10">
        <img src="../../assets/logo.jpg" alt="Gate" class="h-10 w-10" />
        <p
          class="flex flex-col justify-center font-light text-sm leading-[0.5rem] tracking-widest"
        >
          <span class="font-bold text-2xl tracking-wider">GATE</span>Concept Bar
        </p>
      </router-link>
      <div
        class="flex items-center gap-x-7 max-[800px]:gap-x-5 text-xl max-[750px]:text-base font-light max-[480px]:flex-col max-sm:my-5 max-sm:gap-y-2"
      >
        <router-link to="/about" class="hover:text-[#f7c104]"
          >About Us</router-link
        >
        <router-link
          :to="{ path: '/', hash: '#menu' }"
          class="hover:text-[#f7c104]"
          >Menu</router-link
        >
        <router-link
          :to="{ path: '/', hash: '#billiard' }"
          class="hover:text-[#f7c104]"
          >Billiard</router-link
        >
        <router-link to="contact" class="hover:text-[#f7c104]"
          >Contact</router-link
        >
        <router-link to="/jobs" class="hover:text-[#f7c104]"
          >Career</router-link
        >
        <router-link
          to="/book-table"
          class="px-6 py-2 border-2 border-white rounded-xl hover:text-[#f7c104] hover:border-[#f7c104]"
        >
          Reservations
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const isNavbarOpen = ref(false);
    const navbarContainer = ref(null);
    const route = useRoute();

    const toggleNavbar = () => {
      isNavbarOpen.value = !isNavbarOpen.value;
      if (isNavbarOpen.value) {
        document.body.classList.add("navbar-open");
      } else {
        document.body.classList.remove("navbar-open");
      }
    };

    // const handleOutsideClick = (event) => {
    //   if (
    //     navbarContainer.value &&
    //     !navbarContainer.value.contains(event.target)
    //   ) {
    //     isNavbarOpen.value = false;
    //     document.body.classList.remove("navbar-open");
    //   }
    // };

    // onMounted(() => {
    //   watch(isNavbarOpen, (newValue) => {
    //     if (newValue) {
    //       setTimeout(() => {
    //         document.body.addEventListener("click", handleOutsideClick);
    //       }, 0);
    //     } else {
    //       document.body.removeEventListener("click", handleOutsideClick);
    //     }
    //   });
    // });

    // onUnmounted(() => {
    //   document.body.removeEventListener("click", handleOutsideClick);
    //   document.body.classList.remove("navbar-open");
    // });

    return {
      isNavbarOpen,
      toggleNavbar,
      navbarContainer,
      route,
    };
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

body.navbar-open {
  position: fixed;
  width: 100%;
  overflow-y: auto;
}
</style>
