<template>
  <admin-container>
    <div>
      <h1 class="text-2xl font-bold mb-4">Бидний тухай хуудасны зураг</h1>
      <hot-table
        :settings="hotSettings"
        v-if="!isLoading && image.length > 0"
        :data="image"
      ></hot-table>
      <div v-if="isLoading" class="h-[400px]">
        <svg class="animate-spin h-5 w-5" viewBox="0 0 24 24"></svg>
      </div>
      <div
        class="flex items-center justify-center h-[200px]"
        v-else-if="image && image.length === 0 && !isLoading"
      >
        <h1 class="text-2xl font-bold mb-4">Зураг байхгүй байна</h1>
      </div>

      <!-- Зураг нэмэх -->
      <div class="col-span-2 flex flex-col my-10 w-fit">
        <label for="image" class="block mb-2 text-sm font-medium text-gray-900"
          >Зураг сонгох</label
        >
        <input
          type="file"
          name="image"
          id="image"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
          ref="fileInput"
          @change="onFileChange"
        />
        <button class="bg-[#f7c104] p-2 text-sm" @click="addImage">
          Зураг оруулах
        </button>
      </div>

      <!-- Зураг харуулах -->
      <div
        class="col-span-7 relative flex flex-col gap-2 my-5 w-1/2"
        v-show="imageUrl"
      >
        <div class="absolute top-0 right-0">
          <button
            class="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
            @click="resetImage"
          >
            X
          </button>
        </div>
        <img :src="imageUrl" alt="img" class="rounded" />
      </div>
    </div>

    <!-- Мэдээ -->
    <div v-if="editorData.length > 0 && !isLoading">
      <h1 class="text-2xl font-bold">Бидний тухай хуудасны мэдээ</h1>
      <p class="mb-4 text-gray-700 text-sm">Зөвхөн текст оруулна уу.</p>
      <ckeditor
        :editor="editor"
        v-model="editorValue"
        :config="editorConfig"
      ></ckeditor>
      <button
        @click="updateText"
        class="px-4 py-2 bg-[#f7c104] mt-3 text-sm hover:scale-105"
      >
        Мэдээ засах
      </button>
    </div>
    <error-modal :error="error" />
    <success-modal :success="success" v-if="success" />
  </admin-container>
</template>

<script>
import adminInstance from "@/lib/api/adminInstance";
import { ref, onMounted } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "AdminAboutus",
  components: {
    HotTable,
    ErrorModal,
    SuccessModal,
  },
  setup() {
    const isLoading = ref(false);
    const error = ref("");
    const success = ref("");
    const image = ref([]);
    const editorData = ref([]);
    const imageFile = ref("");
    const imageUrl = ref("");
    const fileInput = ref(null);

    const editor = ClassicEditor;
    const editorConfig = {};
    const editorValue = ref("");

    onMounted(async () => {
      await fetchImage();
      await fetchText();
    });

    const fetchImage = async () => {
      try {
        isLoading.value = true;
        const response = await adminInstance.get("/about-us-image");
        if (response.status === 200) {
          image.value = response.data.image;
        }
      } catch (error) {
        return error;
      } finally {
        isLoading.value = false;
      }
    };

    const fetchText = async () => {
      try {
        isLoading.value = true;
        const response = await adminInstance.get("/about-us-text");
        if (response.status === 200) {
          editorData.value = response.data.text;
          editorValue.value = editorData.value[0].content;
        }
      } catch (error) {
        return error;
      } finally {
        isLoading.value = false;
      }
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    const onFileChange = (e) => {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      imageFile.value = file;
      imageUrl.value = URL.createObjectURL(file);
    };

    const resetImage = () => {
      imageUrl.value = "";
      imageFile.value = null;
      if (fileInput.value) {
        fileInput.value.value = "";
      }
    };

    const addImage = async () => {
      try {
        isLoading.value = true;
        const formData = new FormData();
        formData.append("image", imageFile.value);
        const response = await adminInstance.post("/about-us/image", formData);
        if (response.status === 200) {
          await fetchImage();
          await fetchText();
          success.value = response.data.message;
          resetImage();
        }
      } catch (error) {
        error.value = error.response?.data?.message || "An error occurred";
      } finally {
        isLoading.value = false;
        resetMessage();
      }
    };

    const deleteImage = async (row) => {
      try {
        const response = await adminInstance.delete(
          `/about-us/${image.value[row].id}`
        );
        if (response.status === 200) {
          await fetchImage();
          await fetchText();
          success.value = response.data.message;
        }
      } catch (e) {
        error.value = e.response?.data?.message || "An error occurred";
      } finally {
        resetMessage();
      }
    };

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const deleteButtonId = `delete-button-${row}`;
      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
        <button id="${deleteButtonId}" class="bg-red-500 text-white rounded-md text-center p-1">Устгах</button>
      </div>`;
      const deleteButton = document.getElementById(deleteButtonId);
      if (deleteButton) {
        deleteButton.addEventListener("click", () => deleteImage(row));
      }
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center items-center text-[#222222] font-semibold w-full">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<div class="relative flex items-center justify-center py-2">
            <img src="${value}" alt="" class="h-20 w-20 object-fit object-cover" />
          </div>`;
    };

    const hotSettings = {
      colHeaders: ["Зураг", "Зургийг оруулсан огноо", "Зургийг устгах"],
      columns: [
        {
          data: "content",
          readOnly: true,
          renderer: imageRenderer,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "action",
          readOnly: true,
          renderer: actionRenderer,
        },
      ],
      stretchH: "all",
      width: "auto",
      height: "auto",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };
    registerAllModules();

    //Мэдээний хэсэг
    const updateText = async () => {
      try {
        isLoading.value = true;
        const response = await adminInstance.post("/about-us-text/update", {
          content: editorValue.value,
        });
        if (response.status === 200) {
          await fetchText();
          success.value = response.data.message;
        }
      } catch (error) {
        error.value = error.response?.data?.message || "An error occurred";
      } finally {
        isLoading.value = false;
        resetMessage();
      }
    };

    return {
      isLoading,
      hotSettings,
      image,
      editorData,
      addImage,
      onFileChange,
      imageFile,
      imageUrl,
      resetImage,
      fileInput,
      error,
      success,
      updateText,
      editor,
      editorConfig,
      editorValue,
    };
  },
};
</script>
