<template>
  <canvas ref="chartRef"></canvas>
</template>
<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import Chart from "chart.js/auto";

export default {
  name: "ChartComponent",
  props: {
    data: Object,
  },
  setup(props) {
    const chartRef = ref(null);
    let myChart = null;

    const initOrUpdateChart = (chartData) => {
      if (myChart instanceof Chart) {
        myChart.destroy();
      }

      if (chartRef.value) {
        const ctx = chartRef.value.getContext("2d");
        myChart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
          plugins: chartData.plugins,
        });
      }
    };

    onMounted(() => {
      initOrUpdateChart(props.data);
    });

    watch(
      () => props.data,
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          initOrUpdateChart(newVal);
        }
      },
      { deep: true }
    );

    onBeforeUnmount(() => {
      if (myChart instanceof Chart) {
        myChart.destroy();
      }
    });

    return {
      chartRef,
    };
  },
};
</script>
