<template>
  <div class="bg-[#010101]">
    <!-- Navbar -->
    <home-navbar />

    <!-- Эхэнд байгаа зураг -->
    <div class="relative w-full">
      <img
        src="../assets/images/about-us/bartender-1.jpg"
        alt="main"
        class="object-contain object-center w-full h-[30rem] max-sm:object-cover max-sm:h-[80vh]"
      />
      <div
        class="absolute top-0 left-0 w-1/2 h-full flex items-center justify-center"
      >
        <div class="bg-black bg-opacity-50 text-white p-4">
          <h2
            class="text-4xl font-bold text-[#f7c104] uppercase max-sm:text-2xl"
          >
            Ажлын байр
          </h2>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && jobs.length > 0" class="relative">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 my-10 gap-4 overflow-x-scroll"
      >
        <!-- workplace card -->
        <div
          v-for="job in jobs"
          :key="job.id"
          class="flex flex-col h-fit bg-[#353437] text-white rounded-sm pb-4"
        >
          <div class="w-full h-[200px] rounded-sm">
            <img
              :src="job?.image"
              alt=""
              class="w-full h-full object-cover rounded-sm"
            />
          </div>

          <div class="mt-4 border-b pb-4">
            <p
              class="text-center text-xl font-semibold text-[#f7c104] uppercase"
            >
              {{ job?.job_name }}
            </p>
          </div>

          <div class="">
            <div
              class="border-b border-[#ccc] cursor-pointer"
              @click="toggleAccordion(job?.id, 'accordion1')"
            >
              <!-- Add this line for the first accordion -->
              <div class="flex justify-between items-center p-4">
                <h1 class="text-lg font-bold">Ажлын байрны зорилго</h1>
                <button class="text-[#f7c104]">
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    :class="{
                      '-rotate-180': accordionStates[job.id]?.accordion1,
                      'rotate-0': !accordionStates[job.id]?.accordion1,
                    }"
                    class="w-4 h-4 transition-transform duration-300"
                  />
                </button>
              </div>
              <transition name="slide-fade">
                <div
                  v-show="accordionStates[job.id]?.accordion1"
                  class="pb-4 px-4"
                >
                  <p class="text-white text-lg">
                    {{ job?.job_description }}
                  </p>
                </div>
              </transition>
            </div>

            <div
              class="border-b border-[#ccc] cursor-pointer"
              @click="toggleAccordion(job?.id, 'accordion2')"
            >
              <!-- Add this line for the second accordion -->
              <div class="flex justify-between items-center p-4">
                <h1 class="text-lg font-bold">Ажлын цаг</h1>
                <button class="text-[#f7c104]">
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    :class="{
                      '-rotate-180': accordionStates[job.id]?.accordion2,
                      'rotate-0': !accordionStates[job.id]?.accordion2,
                    }"
                    class="w-4 h-4 transition-transform duration-300"
                  />
                </button>
              </div>
              <transition name="slide-fade">
                <div
                  v-show="accordionStates[job.id]?.accordion2"
                  class="pb-4 px-4"
                >
                  <p class="text-white text-lg">
                    {{ job?.job_type }}
                  </p>
                </div>
              </transition>
            </div>

            <div
              class="border-b border-[#ccc] cursor-pointer"
              @click="toggleAccordion(job?.id, 'accordion3')"
            >
              <!-- Add this line for the first accordion -->
              <div class="flex justify-between items-center p-4">
                <h1 class="text-lg font-bold">Цалин урамшуулал</h1>
                <button class="text-[#f7c104]">
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    :class="{
                      '-rotate-180': accordionStates[job.id]?.accordion3,
                      'rotate-0': !accordionStates[job.id]?.accordion3,
                    }"
                    class="w-4 h-4 transition-transform duration-300"
                  />
                </button>
              </div>
              <transition name="slide-fade">
                <div
                  v-show="accordionStates[job.id]?.accordion3"
                  class="pb-4 px-4"
                >
                  <p class="text-white text-lg">
                    {{ job?.job_salary }}
                  </p>
                </div>
              </transition>
            </div>

            <div
              class="border-b border-[#ccc] cursor-pointer"
              @click="toggleAccordion(job?.id, 'accordion4')"
            >
              <!-- Add this line for the first accordion -->
              <div class="flex justify-between items-center p-4">
                <h1 class="text-lg font-bold">Манайд нэгдсэнээр</h1>
                <button class="text-[#f7c104]">
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    :class="{
                      '-rotate-180': accordionStates[job.id]?.accordion4,
                      'rotate-0': !accordionStates[job.id]?.accordion4,
                    }"
                    class="w-4 h-4 transition-transform duration-300"
                  />
                </button>
              </div>
              <transition name="slide-fade">
                <div
                  v-show="accordionStates[job.id]?.accordion4"
                  class="pb-4 px-4"
                >
                  <p class="text-lg">
                    {{ job?.job_benefits }}
                  </p>
                </div>
              </transition>
            </div>
          </div>

          <!-- request button -->
          <div class="flex justify-center items-center mt-4">
            <button
              class="w-[200px] bg-[#f7c104] p-2 rounded-sm hover:bg-[#e8b000] transition duration-300 ease-in-out"
              :class="{
                'cursor-not-allowed bg-gray-400 hover:bg-gray-400':
                  !job?.is_active,
              }"
              :disabled="!job?.is_active"
              @click="handleRequest(job?.job_name)"
            >
              Хүсэлт илгээх
            </button>
          </div>
        </div>
      </div>
      <job-request
        v-if="isRequestModalOpen"
        :job="requestedJob"
        @close="isRequestModalOpen = false"
      />
    </div>
    <div
      v-else
      class="flex justify-center items-center text-white h-[20rem] p-5 max-sm:h-[10rem]"
    >
      <p>Одоогоор ажлын байрны мэдээлэл байхгүй байна</p>
    </div>
    <the-footer />
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import instance from "@/lib/api/clientInstance";
import JobRequest from "@/components/JobRequest.vue";
import HomeNavbar from "@/components/ui/HomeNavbar.vue";
import TheFooter from "@/components/ui/TheFooter.vue";

export default {
  name: "JobsPage",
  components: {
    JobRequest,
    HomeNavbar,
    TheFooter,
  },
  setup() {
    const jobs = ref([]);
    const accordionStates = reactive({});

    const requestedJob = ref("");
    const isRequestModalOpen = ref(false);

    const handleRequest = (jobName) => {
      requestedJob.value = jobName;
      isRequestModalOpen.value = true;
    };

    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      try {
        const response = await instance.get("/jobs");
        if (response.status === 200) {
          response.data.jobs.forEach((job) => {
            accordionStates[job.id] = {
              accordion1: false,
              accordion2: false,
              accordion3: false,
              accordion4: false,
            };
          });
          jobs.value = response.data.jobs;
        }
      } catch (e) {
        console.log(e);
      } finally {
        isLoading.value = false;
      }
    });

    const toggleAccordion = (jobId, accordionKey) => {
      if (accordionStates[jobId]) {
        accordionStates[jobId][accordionKey] =
          !accordionStates[jobId][accordionKey];
      }
    };

    return {
      jobs,
      isLoading,
      requestedJob,
      isRequestModalOpen,
      accordionStates,
      handleRequest,
      toggleAccordion,
    };
  },
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease,
    transform 0.5s ease;
  overflow: hidden;
  transform: translateX(0);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateX(0);
}

.slide-fade-enter-to,
.slide-fade-leave-active {
  max-height: 500px;
  opacity: 1;
  transform: translateX(0);
}

.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateX(0);
}
</style>
