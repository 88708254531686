<template>
  <!-- Menu-ий дээр байгаа зураг -->
  <div class="relative flex justify-center items-center w-full">
    <img
      v-if="images.length > 0"
      :src="images[0].image"
      alt="menu-page"
      class="w-full h-full object-cover object-center"
    />
    <web-nav class="absolute top-0 left-26 max-lg:left-0"></web-nav>
  </div>

  <!-- MENU -->
  <div class="bg-brand min-h-screen">
    <div class="xl:w-[1200px] lg:w-[1020px] mx-auto w-full">
      <div class="py-2" v-if="!isLoading">
        <div
          class="flex justify-between sm:justify-center items-center gap-4 sm:gap-6 text-xl font-bold text-[#f7c104] border-b border-borderColor uppercase text-md p-4 tracking-wide overflow-x-scroll"
        >
          <!-- bottom border for isActive -->
          <div
            v-for="category in categories"
            :key="category"
            :class="isActive(category?.id) ? 'border-b-2 border-[#f7c104]' : ''"
            @click="setCategoryFilter(category?.id)"
            class="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 text-nowrap"
          >
            {{ category?.name }}
          </div>
          <!-- <div class="text-white font-bold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer bg-[#f7c104] py-1 px-2 rounded">
            <router-link to="/book-table"> Reservation </router-link>
          </div> -->
        </div>

        <div
          v-if="subCategories.length > 0"
          class="flex items-center gap-5 text-lg font-bold text-[#f7c104] border-b border-borderColor uppercase text-md p-4 tracking-wide overflow-x-scroll"
        >
          <!-- is active add border around -->
          <div
            v-for="subCategory in subCategories"
            :key="subCategory"
            :class="
              isActiveSub(subCategory?.id)
                ? 'border-2 rounded-md p-1 border-[#f7c104]'
                : ''
            "
            @click="setSubCategoryFilter(subCategory?.id)"
            class="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 whitespace-nowrap text-nowrap"
          >
            {{ subCategory?.name }}
          </div>
        </div>
      </div>

      <!-- <div
        class="p-4 grid md:grid-cols-3 xl:grid-cols-4 grid-cols-1 gap-4"
        v-if="menu.length > 0 && !isLoading"
      >
        <menu-cart
          v-for="item in menu"
          :key="item.id"
          :menuItem="item"
        ></menu-cart>
      </div> -->

      <div
        class="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4"
        v-if="menu.length > 0 && !isLoading"
      >
        <MenuCard v-for="item in menu" :key="item.id" :menu="item" />
      </div>

      <div
        class="flex justify-center items-center py-4"
        v-else-if="menu.length === 0 && !isLoading"
      >
        <p class="text-white text-lg p-5 rounded-lg shadow-lg">
          Цэс хоосон байна.
        </p>
      </div>
    </div>
  </div>
  <web-footer></web-footer>
</template>

<script>
import { ref, computed, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
// import MenuCart from "../components/MenuCart.vue";
import MenuCard from "../components/ui/MenuCard.vue";
import instance from "@/lib/api/clientInstance";

export default {
  name: "MenuPage",
  components: { MenuCard },
  setup() {
    const route = useRoute();
    const menu = ref([]);
    const categories = ref([]);
    const subCategories = ref([]);
    const images = ref([]);

    const currentFilter = reactive({
      cat: null,
      subCat: null,
    });
    const isLoading = ref(false);

    //Fetch menu header зураг
    const getHeaderImage = async () => {
      try {
        isLoading.value = true;
        const response = await instance.get("/menu-header/image");
        images.value = response.data.image;
      } catch (error) {
        return error;
      } finally {
        isLoading.value = false;
      }
    };

    // Fetch menu data
    const getMenu = async () => {
      try {
        isLoading.value = true;
        let gate;
        if (route.path === "/menu/billiard") {
          gate = 5;
        } else {
          gate =
            route.params.gate.split("-")[1] == 4
              ? 1
              : route.params.gate.split("-")[1] == 5
              ? 2
              : route.params.gate.split("-")[1] == 6
              ? 3
              : 4;
        }
        // const gate =
        //   route.params.gate.split("-")[1] == 4
        //     ? 1
        //     : route.params.gate.split("-")[1] == 5
        //     ? 2
        //     : route.params.gate.split("-")[1] == 6
        //     ? 3
        //     : 4;
        const res = await instance.get(`/menu/${gate}`);
        menu.value = res.data.culinary;
        categories.value = res.data.categories;

        currentFilter.cat = res.data.categories[0].id;
        currentFilter.subCat = res.data.subcategories.filter(
          (subCategory) => subCategory.parent_category_id === currentFilter.cat
        )[0].id;

        subCategories.value = res.data.subcategories;
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    const filteredSubCategories = computed(() => {
      return (
        subCategories.value.filter(
          (subCategory) =>
            subCategory.parent_category_id === currentFilter.cat &&
            subCategory.parent_category_id !== null
        ) || []
      );
    });

    // Computed property to filter menu items
    const filteredMenu = computed(() => {
      if (!currentFilter.cat) {
        return menu.value;
      }
      if (!currentFilter.subCat) {
        return menu.value.filter(
          (menuItem) => menuItem.root_category_id === currentFilter.cat
        );
      }
      return menu.value.filter(
        (menuItem) => menuItem.category_id === currentFilter.subCat
      );
    });

    // Function to update the current filter
    const setCategoryFilter = (filter) => {
      currentFilter.cat = filter;
      if (subCategories.value.length === 0) return;
      const matchingSubCategories = subCategories.value.filter(
        (subCategory) => subCategory.parent_category_id === filter
      );
      if (matchingSubCategories.length > 0) {
        currentFilter.subCat = matchingSubCategories[0].id;
      } else {
        currentFilter.subCat = null;
      }
    };

    const setSubCategoryFilter = (filter) => {
      currentFilter.subCat = filter;
    };

    onMounted(async () => {
      await getMenu();
      await getHeaderImage();
    });

    const isActive = (filter) => {
      return currentFilter.cat === filter;
    };

    const isActiveSub = (filter) => {
      return currentFilter.subCat === filter;
    };

    return {
      isLoading,
      menu: filteredMenu,
      categories,
      subCategories: filteredSubCategories,
      setCategoryFilter,
      setSubCategoryFilter,
      isActive,
      isActiveSub,
      images,
    };
  },
};
</script>

<style>
/* remove scroll bar */
.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}
/* Add this to your <style> section */
.scrollable-hint {
  position: relative;
}
.scrollable-hint::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to right, transparent, #010101);
}
.scrollable-hint::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to left, transparent, #010101);
}
</style>
