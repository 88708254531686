<template>
  <div
    class="flex flex-col bg-[#010101] text-white gap-y-20 max-sm:gap-y-10 font-bold"
  >
    <div
      class="first_section h-[100vh] relative"
      :style="`background-image: url(${backGroundImage})`"
    >
      <HomeNavbar class="absolute top-0 left-0" />

      <!-- Intro -->
      <div
        class="intro flex flex-col justify-center items-center h-full leading-[10rem] max-[400px]:leading-none max-[500px]:text-center mt-10"
      >
        <h6
          class="text-[#f7c104] text-[10rem] max-[400px]:text-[5rem] max-sm:text-[7rem]"
        >
          GATE
        </h6>
        <p class="leading-3 text-2xl max-[500px]:leading-none max-[500px]:mt-3">
          Monday - Sunday <br class="max-[500px]:flex hidden" />
          <span class="max-[400px]:text-sm max-sm:text-xl"
            >12:00pm - 12:00am</span
          >
        </p>
        <div
          class="flex gap-x-5 text-xl mt-10 max-sm:text-base max-[400px]:gap-x-5 max-[400px]:mt-5"
        >
          <router-link
            :to="{ path: '/', hash: '#menu' }"
            class="first-section-button w-[9rem] max-[400px]:w-[6rem] max-[400px]:text-sm text-center uppercase py-2 border border-white rounded-full bg-white text-black cursor:pointer overflow-hidden hover:text-white"
          >
            Menu
          </router-link>
          <router-link
            to="/book-table"
            class="first-section-button w-[9rem] max-[400px]:w-[6rem] max-[400px]:text-sm text-center uppercase py-2 border border-white rounded-full bg-white text-black cursor:pointer overflow-hidden hover:text-white"
          >
            Reservations
          </router-link>
        </div>
      </div>
    </div>

    <!-- Main -->
    <main
      class="flex flex-col gap-y-20 items-center justify-center w-full h-full"
    >
      <!-- Menu -->
      <div id="menu" class="max-w-[1200px] w-full max-[1210px]:px-5">
        <h1 class="text-5xl text-center mb-10 max-md:text-3xl">Menu</h1>
        <div
          class="grid grid-cols-3 gap-20 max-[550px]:grid-cols-1 min-[400px]:max-[550px]:px-8 max-[900px]:grid-cols-2 max-[900px]:gap-10"
        >
          <branch-card
            v-for="(branch, index) in restaurant"
            :key="index"
            :branch="branch"
          />
        </div>
      </div>

      <!-- Billiard -->
      <div id="billiard" class="max-w-[1200px] w-full max-[1210px]:px-5">
        <h1 class="text-5xl text-center mb-10 max-md:text-3xl">
          Billiard Club
        </h1>
        <div
          class="grid grid-cols-3 gap-x-20 max-[550px]:grid-cols-1 min-[400px]:max-[550px]:px-8 max-[900px]:grid-cols-2 max-[900px]:gap-10"
        >
          <branch-card
            v-for="(branch, index) in billiard"
            :key="index"
            :branch="branch"
          />
        </div>
      </div>

      <!-- map -->
      <div id="locations" class="max-w-[1200px] w-full mb-10">
        <h1
          class="flex items-center gap-3 max-sm:gap-2 text-4xl mb-5 max-sm:text-xl max-md:text-2xl text-start max-[1210px]:px-5"
        >
          <font-awesome-icon
            icon="fa-solid fa-location-dot"
            class="text-[#f7c104]"
          />
          Branch locations
        </h1>
        <div class="h-[400px]">
          <l-map :zoom="zoom" :center="center" :min-zoom="zoom">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker
              v-for="(location, index) in locations"
              :key="index"
              :lat-lng="location.location"
              :icon="customIcon"
            >
              <l-popup
                :content="location.label"
                :permanent="true"
                :direction="'top'"
                :offset="[0, -20]"
              ></l-popup
            ></l-marker>
          </l-map>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <TheFooter />
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import BranchCard from "@/components/BranchCard.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import instance from "@/lib/api/clientInstance";
import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import L from "leaflet";
import defaultImage from "../assets/images/gate-5/main.jpg";
import HomeNavbar from "@/components/ui/HomeNavbar.vue";

export default {
  name: "HomePage",
  components: {
    BranchCard,
    TheFooter,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    HomeNavbar,
  },
  setup() {
    //Салбаруудын мэдээлэл
    const branches = ref([]);
    const fetchBranches = async () => {
      try {
        const res = await instance.get("/branch");
        branches.value = res.data.branches;
      } catch (error) {
        return error;
      }
    };

    /* 
      background image
    */
    const backGroundImage = ref("");
    const fetchMainImage = async () => {
      try {
        const response = await instance.get("/main-image");
        if (!response.data.mainImage) backGroundImage.value = defaultImage;
        if (response.status === 200)
          backGroundImage.value = response.data.mainImage;
      } catch (error) {
        backGroundImage.value = defaultImage;
      }
    };

    onMounted(async () => {
      await fetchMainImage();
      await fetchBranches();
    });

    const url = ref("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    const attribution = ref(
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    );

    const zoom = computed(() => {
      if (window.innerWidth < 600) {
        return 14;
      } else {
        return 15;
      }
    });

    const center = ref([47.92026, 106.91271]);
    const locations = ref([
      {
        label:
          "Gate 4 && Billiard Club - Peacemall-н зүүн CU тэй талаар хойшоо эргээд",
        location: [47.9168, 106.90224],
      },
      {
        label: "Gate 5 - Чингэлтэй Дүүрэг, БОАЖЯамны чанх урд байрлаж байна.",
        location: [47.92297, 106.91308],
      },
      {
        label:
          "Gate 6 - Спортын төв ордны замын эсрэг талд петровисын 1 давхарт",
        location: [47.92102, 106.92281],
      },
    ]);
    const customIcon = L.icon({
      iconUrl: require("../assets/logo.jpg"),
      iconSize: [28, 28],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    // const restaurant = computed(() =>
    //   branches.value.filter((branch) => branch.type === "Restaurant")
    // );

    const restaurant = computed(() =>
      branches.value.filter(
        (branch) => branch.type === "Restaurant" && branch.name !== "Gate 7"
      )
    );

    const billiard = computed(() =>
      branches.value.filter((branch) => branch.type === "Billiard")
    );

    return {
      backGroundImage,
      restaurant,
      billiard,
      url,
      attribution,
      zoom,
      center,
      locations,
      customIcon,
    };
  },
};
</script>

<style scoped>
.first_section {
  position: relative;
  animation: changeBackground 30s linear infinite;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes changeBackground {
  0%,
  30%,
  33.33%,
  63.33%,
  66.66%,
  100% {
    opacity: 1;
  }
}

.first-section-button {
  --initialBgColor: transparent;
  --slideBgColor: #010101;
  background-image: linear-gradient(
    90deg,
    var(--initialBgColor) 0%,
    var(--initialBgColor) 50%,
    var(--slideBgColor) 50%,
    var(--slideBgColor) 100%
  );
  background-size: 200%;
  transition: background-position 0.5s cubic-bezier(0.47, 0.1, 1, 0.63),
    color 0.2s ease-in-out;
  transition-delay: 0s, 0.15s;
}

.first-section-button:hover {
  cursor: pointer;
  background-position: -100%;
}

.intro {
  animation: fadeInDown 3s;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
