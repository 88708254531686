<template>
  <admin-container>
    <h1 class="text-3xl font-bold mb-8">Ажил нэмэх</h1>
    <!-- set grid width -->
    <div class="grid grid-flow-row gap-4 md:w-[70%] lg:w-[50%]">
      <!-- image upload -->
      <div class="col-span-3">
        <label
          for="jobImage"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Зураг</label
        >
        <input
          type="file"
          name="image"
          id="image"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          ref="fileInput"
          @change="onFileChange"
        />
      </div>
      <div class="col-span-3">
        <label
          for="jobName"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Ажлын нэр</label
        >
        <input
          id="jobName"
          name="jobName"
          placeholder="Ажлын нэр"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="text"
          v-model="form.jobName"
          @input="handleFormInput"
        />
      </div>

      <div class="col-span-3">
        <label
          for="jobDescription"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Ажлын тайлбар</label
        >
        <!-- set row number textarea -->
        <textarea
          id="jobDescription"
          name="jobDescription"
          rows="6"
          placeholder="Ажлын тайлбар"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="text"
          v-model="form.jobDescription"
          @input="handleFormInput"
        ></textarea>
      </div>

      <div class="col-span-3">
        <label
          for="jobType"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Ажлын төрөл</label
        >
        <select
          id="jobType"
          name="jobType"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.jobType"
          @input="handleFormInput"
        >
          <option value="Цагаар">Цагаар</option>
          <option value="Ээлжээр">Ээлжээр</option>
          <option value="Бүтэн цагийн">Бүтэн цагаар</option>
        </select>
      </div>

      <div class="col-span-3">
        <label
          for="jobSalary"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Цалин</label
        >
        <textarea
          id="jobSalary"
          name="jobSalary"
          placeholder="Цалин, урамшуулал"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="text"
          v-model="form.jobSalary"
          @input="handleFormInput"
        ></textarea>
      </div>

      <!-- benefits -->
      <div class="col-span-3">
        <label
          for="jobBenefits"
          class="block mb-2 text-sm font-medium text-gray-900"
          >Манайд ажилд орсоноор</label
        >
        <textarea
          id="jobBenefits"
          name="jobBenefits"
          rows="6"
          placeholder="Манайд ажилд орсоноор"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="text"
          v-model="form.jobBenefits"
          @input="handleFormInput"
        >
        </textarea>
      </div>
    </div>
    <div class="mt-4">
      <button
        v-if="!id"
        class="flex justify-center items-center bg-brand text-white p-2 rounded-md hover:opacity-90 transition duration-200 ease-in-out"
        @click="handleSubmit"
        :disabled="isLoading"
      >
        Ажил оруулах
      </button>
      <button
        v-else
        class="flex justify-center items-center bg-brand text-white p-2 rounded-md hover:opacity-90 transition duration-200 ease-in-out"
        @click="handleUpdate"
        :disabled="isLoading"
      >
        Ажил засах
      </button>
    </div>
    <error-modal :error="error" v-if="error" />
    <success-modal :success="success" v-if="success" />
  </admin-container>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import adminInstance from "@/lib/api/adminInstance";
import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";

export default {
  name: "AddJob",
  components: {
    ErrorModal,
    SuccessModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const form = reactive({
      jobName: "",
      jobDescription: "",
      jobType: "",
      jobSalary: "",
      jobBenefits: "",
      imageFile: null,
    });

    const fileInput = ref(null);

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    const id = route.params?.id;
    onMounted(() => {
      if (id) {
        getJob(id);
      }
    });

    const getJob = async (id) => {
      isLoading.value = true;
      try {
        const response = await adminInstance.get(`/jobs/${id}`);
        if (response.status === 200) {
          form.jobName = response.data.job.job_name;
          form.jobDescription = response.data.job.job_description;
          form.jobType = response.data.job.job_type;
          form.jobSalary = response.data.job.job_salary;
          form.jobBenefits = response.data.job.job_benefits;
        }
        console.log(response.data.job);
      } catch (e) {
        error.value = e.response.data.message;
      } finally {
        isLoading.value = false;
      }
    };

    const handleFormInput = (e) => {
      form[e.target.name] = e.target.value;
    };

    const onFileChange = (e) => {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      form.imageFile = file;
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    const resetForm = () => {
      form.jobName = "";
      form.jobDescription = "";
      form.jobType = "";
      form.jobSalary = "";
      form.jobBenefits = "";
      form.imageFile = null;
      fileInput.value.value = "";
    };

    const validateForm = () => {
      if (
        !form.imageFile ||
        !form.jobName ||
        !form.jobDescription ||
        !form.jobType ||
        !form.jobSalary ||
        !form.jobBenefits
      ) {
        error.value = "Бүх талбарыг бөглөнө үү";
        return false;
      }
      return true;
    };

    const validateFormUpdate = () => {
      if (
        !form.jobName ||
        !form.jobDescription ||
        !form.jobType ||
        !form.jobSalary ||
        !form.jobBenefits
      ) {
        error.value = "Бүх талбарыг бөглөнө үү";
        return false;
      }
      return true;
    };

    const handleUpdate = async () => {
      isLoading.value = true;
      if (!validateFormUpdate()) {
        isLoading.value = false;
        resetMessage();
        return;
      }
      const formData = new FormData();

      formData.append("jobName", form.jobName);
      formData.append("jobDescription", form.jobDescription);
      formData.append("jobType", form.jobType);
      formData.append("jobSalary", form.jobSalary);
      formData.append("jobBenefits", form.jobBenefits);

      // Only append the image file to the form data if the user has selected a new image
      if (form.imageFile) {
        formData.append("image", form.imageFile);
      }

      try {
        const res = await adminInstance.post(
          `/jobs/${route.params.id}`,
          formData
        );
        if (res.status === 200) {
          success.value = res.data.message;
          resetForm();
          router.push("/admin/add-job");
        }
      } catch (err) {
        error.value = err.response.data.message;
      } finally {
        isLoading.value = false;
        resetMessage();
      }
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      if (!validateForm()) {
        isLoading.value = false;
        resetMessage();
        return;
      }
      const formData = new FormData();

      formData.append("jobName", form.jobName);
      formData.append("jobDescription", form.jobDescription);
      formData.append("jobType", form.jobType);
      formData.append("jobSalary", form.jobSalary);
      formData.append("jobBenefits", form.jobBenefits);
      formData.append("image", form.imageFile);

      try {
        const res = await adminInstance.post("/jobs", formData);
        if (res.status === 200) {
          success.value = res.data.message;
          resetForm();
        }
      } catch (err) {
        error.value = err.response.data.message;
      } finally {
        isLoading.value = false;
        resetMessage();
      }
    };

    return {
      id,
      form,
      fileInput,
      error,
      success,
      isLoading,
      onFileChange,
      handleFormInput,
      handleSubmit,
      handleUpdate,
    };
  },
};
</script>
