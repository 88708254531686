<template>
  <admin-container>
    <div>
      <h1 class="text-2xl font-bold mb-4">Вэбийн хандалт</h1>
      <div
        class="flex justify-center items-center h-[10rem] sm:h-[20rem] md:h-[30rem] my-5 lg:my-10"
      >
        <ChartComponent v-if="chartData" :data="chartData" />
      </div>
    </div>
    <div>
      <h1 class="text-2xl font-bold mb-4">Хэрэглэгчийн санал, хүсэлт</h1>
      <hot-table
        :settings="hotSettings"
        v-if="!isLoading && customerFeedback.length > 0"
        :data="customerFeedback"
      ></hot-table>
      <div v-if="isLoading" class="h-[400px]">
        <svg class="animate-spin h-5 w-5" viewBox="0 0 24 24"></svg>
      </div>
      <div
        class="flex items-center justify-center h-[200px]"
        v-else-if="
          customerFeedback && customerFeedback.length === 0 && !isLoading
        "
      >
        <h1
          class="text-2xl max-md:text-lg max-lg:text-xl max-lg:text-gray-500 max-md:text-center md:font-bold mb-4"
        >
          Санал, хүсэлт байхгүй байна
        </h1>
      </div>
    </div>
  </admin-container>
</template>

<script>
import { onMounted, ref } from "vue";
import adminInstance from "../../lib/api/adminInstance";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";
import ChartComponent from "../../components/ui/ChartComponent";
import chartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "CustomerFeedback",
  components: {
    HotTable,
    ChartComponent,
  },
  setup() {
    const customerFeedback = ref([]);
    const chartData = ref();
    const isLoading = ref(false);

    onMounted(async () => {
      await fetchCustomerFeedback();
      await processVisitorData();
    });

    const processVisitorData = async () => {
      try {
        const res = await adminInstance.get("/all-visitors");

        chartData.value = {
          type: "line",
          data: {
            labels: res.data.labels,
            datasets: [
              {
                data: res.data.data,
                label: "Хандалт",
                fill: false,
                borderColor: "#FF6384",
                borderWidth: 2,
                tension: 0.1,
                spanGaps: true,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Хэрэглэгчдийн хандалт",
                font: {
                  size: 16,
                },
                padding: {
                  top: 10,
                  bottom: 30,
                },
              },
              datalabels: {
                color: "#000",
                anchor: "end",
                align: "end",
                offset: 0,
                font: {
                  size: 14,
                },
              },
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  boxWidth: 40,
                  padding: 30,
                  font: {
                    size: 14,
                  },
                },
              },
            },
          },
          plugins: [chartDataLabels],
        };
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCustomerFeedback = async () => {
      try {
        isLoading.value = true;
        const response = await adminInstance.get("/customer-feedback");
        if (response.status === 200) {
          customerFeedback.value = response.data.feedback;
        }
      } catch (error) {
        return error;
      } finally {
        isLoading.value = false;
      }
    };

    const handleDeleteRow = async (row) => {
      try {
        const response = await adminInstance.delete(
          `/customer-feedback/${customerFeedback.value[row].id}`
        );
        if (response.status === 200) {
          await fetchCustomerFeedback();
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleFeedbackSolved = async (row, newValue) => {
      try {
        const updatedRow = { ...customerFeedback.value[row], solved: newValue };
        const response = await adminInstance.post(
          `/customer-feedback/update`,
          updatedRow
        );
        if (response.status === 200) {
          await fetchCustomerFeedback();
        }
      } catch (e) {
        console.error(e);
      }
    };

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const deleteButtonId = `delete-button-${row}`;
      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
        <button id="${deleteButtonId}" class="bg-red-500 text-white rounded-md text-center p-1">Устгах</button>
      </div>`;
      const deleteButton = document.getElementById(deleteButtonId);
      if (deleteButton) {
        deleteButton.addEventListener("click", () => handleDeleteRow(row));
      }
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      const status = instance.getDataAtRowProp(row, "solved");
      if (status === false) {
        td.style.backgroundColor = "#FECACA";
      } else if (status === true) {
        td.style.backgroundColor = "#A7F3D0";
      }
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="text-[#222222] font-semibold text-center">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const checkboxRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.classList.add("htCenter");
      td.innerHTML = `<input type="checkbox" ${
        value ? "checked" : ""
      } class="form-checkbox h-5 w-5 text-blue-600"  />`;
      const checkbox = td.querySelector("input");
      checkbox.addEventListener("click", (event) => {
        handleFeedbackSolved(row, event.target.checked);
      });
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Нэр",
        "Имэйл",
        "Санал, хүсэлт",
        "Илгээсэн огноо",
        "Ip хаяг",
        "Шийдвэрлэсэн эсэх",
        "",
      ],
      columns: [
        {
          data: "name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "email",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "feedback",
          readOnly: true,
          renderer: commonRenderer,
          width: 250,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "ip_address",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "solved",
          type: "checkbox",
          renderer: checkboxRenderer,
        },
        {
          data: "action",
          readOnly: true,
          renderer: actionRenderer,
        },
      ],
      stretchH: "all",
      fixedColumnsLeft: 2,
      width: "100%",
      height: "auto",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };
    registerAllModules();

    return {
      customerFeedback,
      hotSettings,
      isLoading,
      chartData,
    };
  },
};
</script>
