export const vipRooms = {
  gateFour: [
    {
      id: 1,
      branch: "4",
      capacity: "6-12",
      price: 20000,
      image: require("../assets/images/vip-room/gate4_1.jpg"),
    },
  ],
  gateFive: [
    {
      id: 1,
      branch: "5",
      capacity: "10-20",
      price: 40000,
      image: require("../assets/images/vip-room/gate5_1.jpg"),
    },
    // {
    //   id: 2,
    //   branch: "5",
    //   capacity: "10-20",
    //   price: 40000,
    //   image: require("../assets/images/vip-room/gate5_2.jpg"),
    // },
  ],
  gateSix: [
    {
      id: 1,
      branch: "6",
      capacity: "16",
      price: 40000,
      image: require("../assets/images/vip-room/gate6_1.jpeg"),
    },
    {
      id: 2,
      branch: "6",
      capacity: "12",
      price: 30000,
      image: require("../assets/images/vip-room/gate6_2.jpeg"),
    },
    {
      id: 3,
      branch: "6",
      capacity: "6",
      price: 20000,
      image: require("../assets/images/vip-room/gate6_3.jpg"),
    },
  ],
};
