<template>
  <div class="fixed top-5 left-0 w-full h-full bg-black bg-opacity-50 z-50">
    <div class="relative grid grid-cols-2 gap-4 max-w-[768px] mx-auto p-8 bg-white">
      <!-- close button -->
      <button class="absolute top-2 right-2" @click="closeModal">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 hover:text-gray-900 transition duration-300 ease-in-out" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div class="col-span-1">
        <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900"> Овог <span class="text-red-500">*</span></label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          placeholder="Овог"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.lastName"
          @input="handleFormInput"
        />
      </div>
      <div class="">
        <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900"> Нэр <span class="text-red-500">*</span></label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          placeholder="Нэр"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.firstName"
          @input="handleFormInput"
        />
      </div>
      <div class="">
        <label for="gender" class="block mb-2 text-sm font-medium text-gray-900"> Хүйс <span class="text-red-500">*</span></label>
        <select
          id="gender"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.gender"
          @input="handleFormInput"
        >
          <option value="Эрэгтэй">Эрэгтэй</option>
          <option value="Эмэгтэй">Эмэгтэй</option>
        </select>
      </div>
      <div class="">
        <label for="birthDate" class="block mb-2 text-sm font-medium text-gray-900"> Төрсөн огноо <span class="text-red-500">*</span></label>
        <input
          id="birthDate"
          name="birthDate"
          type="date"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.birthDate"
          @input="handleFormInput"
        />
      </div>
      <!-- phone -->
      <div class="">
        <label for="phone" class="block mb-2 text-sm font-medium text-gray-900"> Утас <span class="text-red-500">*</span></label>
        <input
          id="phone"
          name="phone"
          type="text"
          placeholder="Утас"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.phone"
          @input="handleFormInput"
        />
      </div>
      <!-- email -->
      <div class="">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900"> И-мэйл <span class="text-red-500">*</span></label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="И-мэйл"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.email"
          @input="handleFormInput"
        />
      </div>
      <!-- primary occupation -->
      <div class="">
        <label for="primaryOccupation" class="block mb-2 text-sm font-medium text-gray-900"> Үндсэн мэргэжил <span class="text-red-500">*</span></label>
        <input
          id="primaryOccupation"
          name="primaryOccupation"
          type="text"
          placeholder=""
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.primaryOccupation"
          @input="handleFormInput"
        />
      </div>
      <!-- file upload cv -->
      <!-- <div class="">
        <label for="cv" class="block mb-2 text-sm font-medium text-gray-900"> CV хавсаргах </label>
        <input
          type="file"
          name="cv"
          id="cv"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          @change="handleFileInput"
        />
      </div> -->
      <!-- exprience -->
      <!-- set word limit to 254 -->
      <div class="col-span-2">
        <label for="experience" class="block mb-2 text-sm font-medium text-gray-900"> Туршлага <span class="text-red-500">*</span></label>
        <textarea
          id="experience"
          name="experience"
          rows="5"
          maxlength="300"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          v-model="form.experience"
          @input="handleFormInput"
        ></textarea>
      </div>

      <!-- request button -->
      <div class="col-span-2 flex justify-center items-center mt-4">
        <button class="w-[200px] bg-[#f7c104] p-2 rounded-sm hover:bg-[#e8b000] transition duration-300 ease-in-out" @click="handleSubmit" :disabled="isLoading">
          <span v-if="isLoading" class="flex items-center justify-center gap-2">
            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="white" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
            <span>Түр хүлээнэ үү...</span>
          </span>
          <span v-else>Хүсэлт илгээх</span>
        </button>
      </div>
    </div>

    <error-modal modal :error="error" v-if="error" />
    <success-modal modal :success="success" v-if="success" />
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import instance from "@/lib/api/clientInstance";
import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";
export default {
  name: "JobRequest",
  components: {
    ErrorModal,
    SuccessModal,
  },
  props: {
    job: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.job);

    const form = reactive({
      requestedJob: props.job,
      lastName: "",
      firstName: "",
      gender: "",
      birthDate: "",
      phone: "",
      email: "",
      primaryOccupation: "",
      experience: "",
      cv: "",
    });

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    const handleFormInput = (e) => {
      form[e.target.name] = e.target.value;
    };

    const handleFileInput = (e) => {
      if (!e.target.files.length) return;
      form.cv = e.target.files[0];
    };

    const validatePhone = (phone) => {
      const phoneRegex = /^[0-9]{8}$/;
      return phoneRegex.test(phone);
    };

    const validateEmail = (email) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    };

    const validateForm = () => {
      if (!form.lastName || !form.firstName || !form.gender || !form.birthDate || !form.phone || !form.email || !form.primaryOccupation || !form.experience) {
        return false;
      }
      if (!validatePhone(form.phone)) {
        error.value = "Утасны дугаар буруу байна";
        return false;
      }
      if (!validateEmail(form.email)) {
        error.value = "И-мэйл хаяг буруу байна";
        return false;
      }
      return true;
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    const resetForm = () => {
      form.requestedJob = props.job;
      form.lastName = "";
      form.firstName = "";
      form.gender = "";
      form.birthDate = "";
      form.phone = "";
      form.email = "";
      form.primaryOccupation = "";
      form.experience = "";
      form.cv = "";
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      console.log(form);

      if (!validateForm()) {
        error.value = "Мэдээллийг бүрэн оруулна уу";
        isLoading.value = false;
        resetMessage();
        return;
      }
      const formData = new FormData();
      formData.append("requestedJob", form.requestedJob);
      formData.append("lastName", form.lastName);
      formData.append("firstName", form.firstName);
      formData.append("gender", form.gender);
      formData.append("birthDate", form.birthDate);
      formData.append("phone", form.phone);
      formData.append("email", form.email);
      formData.append("primaryOccupation", form.primaryOccupation);
      formData.append("experience", form.experience);
      formData.append("file", form.cv);

      try {
        const response = await instance.post("/job-request", formData);
        if (response.status === 200) {
          success.value = response.data.message;
          resetForm();
          let timer = setTimeout(() => {
            emit("close");
            clearTimeout(timer);
          }, 3000);
        } else {
          error.value = response.data.message;
        }
      } catch (e) {
        error.value = e.response.data.message;
      } finally {
        resetMessage();
        isLoading.value = false;
      }
    };

    return {
      form,
      error,
      success,
      isLoading,
      closeModal: () => emit("close"),
      handleFileInput,
      handleFormInput,
      handleSubmit,
    };
  },
};
</script>
