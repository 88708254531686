<template>
  <transition name="fade">
    <div
      v-if="success"
      class="fixed top-0 right-2 r flex items-center justify-center px-4 py-6 pointer-events-none z-[100]"
    >
      <div
        class="max-w-sm w-full bg-green-500 text-white p-4 rounded-lg shadow-lg text-sm flex items-center space-x-2"
      >
        <span>{{ success }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SuccessModal",
  props: {
    success: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
