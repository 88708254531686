import axios from "axios";

let baseURL;
if (process.env.NODE_ENV === "production") {
  baseURL = "https://www.gatebar.mn/api/admin";
} else {
  baseURL = "http://localhost/api/admin";
}

const adminInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default adminInstance;
