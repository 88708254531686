<template>
  <div
    v-show="isOpen"
    class="fixed inset-0 overflow-y-auto flex items-center justify-center"
  >
    <div
      class="fixed inset-0 bg-black opacity-50 cursor-pointer"
      @click="closeModal"
    ></div>

    <div class="relative p-4 bg-white rounded-md md:w-96 w-64">
      <div class="flex justify-between">
        <h2 class="font-light mb-4 text-[#010101]">Дэлгэрэнгүй</h2>
        <span class="cursor-pointer" @click="closeModal">
          <img src="../assets/svg/cross.svg" alt="" class="w-5 h-5" />
        </span>
      </div>
      <img
        :src="menuItem?.image"
        alt="img"
        class="w-full h-40 md:h-64 rounded"
      />
      <p class="font-bold text-base text-black mt-3 uppercase">
        {{ menuItem?.name }}
      </p>
      <p class="text-[#f7c104] font-semibold mb-3">{{ menuItem?.price }} ₮</p>
      <p class="text-gray-600 text-justify text-sm">
        {{ menuItem?.description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    menuItem: Object,
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit("on-close", false);
    };

    return {
      closeModal,
    };
  },
};
</script>
