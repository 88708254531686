<template>
  <div class="bg-brand border-t border-borderColor">
    <div class="xl:w-[1200px] lg:w-[1020px] mx-auto text-white px-4">
      <div
        v-if="currentBranch"
        class="text-[#f7c104] border-b border-borderColor uppercase py-4 tracking-wide text-sm"
      >
        {{ currentBranch?.name + " - " + currentBranch?.address }}
      </div>
      <div
        class="px-4 py-8 text-sm flex sm:flex-row flex-col gap-4 justify-between sm:items-center"
      >
        <div class="flex gap-4 items-center">
          <font-awesome-icon
            icon="fa-solid fa-phone"
            class="w-4 h-4 text-[#f7c104]"
          />
          <div>
            <p>+(976) 7744 4949</p>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <font-awesome-icon
            icon="fa-solid fa-envelope"
            class="w-4 h-4 text-[#f7c104]"
          />
          <p>Blackgatecatering@gmail.com</p>
        </div>
        <div class="flex gap-4 items-center">
          <img src="../../assets/svg/alarm.svg" alt="" class="w-4 h-4" />
          <p>12:00 pm - 12:00 am</p>
        </div>
      </div>

      <div
        class="flex md:flex-row flex-col items-center gap-4 md:justify-between py-4 pr-4 text-sm cursor-pointer border-y border-[#212529]"
      >
        <div class="flex items-center gap-4 mb-4 sm:mb-0">
          <div class="md:border-r border-yellow px-4">
            <img
              src="../../assets/logo.jpg"
              alt=""
              class="w-16 h-12 md:w-28 md:h-20 rounded-full object-fit object-center"
            />
          </div>
          <p class="w-full text-justify">
            &copy;2024 Gate Concept Bar. All Rights Reserved.
          </p>
        </div>
        <div class="flex gap-5 items-center">
          <a href="https://www.facebook.com/blackgate4" target="_blank">
            <img src="../../assets/svg/facebook.svg" alt="" class="w-5 h-5" />
          </a>
          <a href="https://www.instagram.com/gate_concept_bar/" target="_blank">
            <img src="../../assets/svg/instagram.svg" alt="" class="w-5 h-5" />
          </a>
        </div>
      </div>

      <div class="p-4 text-center md:text-right text-xs capitalize font-bold">
        <a
          href="https://bowsys.mn"
          target="_blank"
          class="text-yellow hover:text-orange-400"
        >
          developed by BOWSYS
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "WebFooter",
  setup() {
    const route = useRoute();
    const branches = [
      {
        name: "Gate 4",
        address: "Peacemall-н зүүн CU тэй талаар хойшоо эргээд.",
        to: "gate-4",
      },
      {
        name: "Gate 5",
        address: "Чингэлтэй Дүүрэг, БОАЖЯамны чанх урд байрлаж байна.",
        to: "gate-5",
      },
      {
        name: "Gate 6",
        address: "Спортын төв ордны замын эсрэг талд петровисын 1 давхарт",
        to: "gate-6",
      },
      {
        name: "Gate 7",
        address: "Улаанбаатар хот",
        to: "gate-7",
      },
      {
        name: "Gate Billiard Club",
        address:
          "Peacemall-н зүүн CU тэй талаар хойшоо эргээд Gate 4-ийн B1 давхарт",
        to: "billiard",
      },
    ];

    const currentBranch = computed(() => {
      if (route.path === "/menu/billiard") {
        return branches.find((branch) => branch.to === "billiard");
      } else if (route.params.gate) {
        return branches.find((branch) => branch.to === route.params.gate);
      } else {
        return null;
      }
    });

    return {
      branches,
      currentBranch,
    };
  },
};
</script>
