<template>
  <div class="relative bg-gray-100 h-screen flex justify-center items-center font-sans">
    <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-xs">
      <h1 class="text-2xl font-semibold mb-4">Админ нэвтрэх</h1>
      <div class="mb-4">
        <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
        <input type="text" id="username" name="username" class="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300" v-model="username" @keyup.enter="login" />
      </div>
      <div class="mb-4">
        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
        <input type="password" id="password" name="password" class="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300" v-model="password" @keyup.enter="login" />
      </div>
      <button type="submit" class="bg-brand text-white p-2 rounded-md w-full hover:opacity-90 transition duration-200 ease-in-out" @click="login">Login</button>
    </div>
    <error-modal :error="error" />
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import adminInstance from "@/lib/api/adminInstance";
import ErrorModal from "@/components/admin/ErrorModal.vue";
export default {
  components: {
    ErrorModal,
  },
  name: "AdminLogin",
  setup() {
    const router = useRouter();
    const username = ref("");
    const password = ref("");

    const error = ref("");

    const login = async () => {
      try {
        const response = await adminInstance.post("/login", {
          username: username.value,
          password: password.value,
        });
        if (response && response.status === 200) {
          router.push("/admin/reservation-list");
        } else {
          error.value = "Алдаа гарлаа!";
          return;
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          error.value = e.response.data.message;
        } else {
          error.value = "Алдаа гарлаа!";
        }
      }
    };

    return {
      username,
      password,
      error,
      login,
    };
  },
};
</script>
