import axios from "axios";

let baseURL;
if (process.env.NODE_ENV === "production") {
  baseURL = "https://www.gatebar.mn/api/client";
} else {
  baseURL = "http://localhost/api/client";
}

const instance = axios.create({
  baseURL,
});

export default instance;
